import { React } from "react";
import { useEffect, useState } from "react";
import useCallApi from "../hooks/useCallApi";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ModalFormTeacher from "../components/ModalFormTeacher";

function Teachers() {

    const callApi = useCallApi();
    const [teachers, setTeachers] = useState([]);
    const [filtredData, setFiltredData] = useState();

    //send inputs to the hook to generate the form
    //placeholder + required + type
    const inputs = {
        "name": ["Nombre", true, "text"],
        "surname": ["Apellido", true, "text"],
        "dni": ["Dni", false, "number"],
        "address": ["Direccion", false, "text"],
        "phone": ["Telefono", false, "text"],
        "birthdate": ["Nacimiento", false, "date"],
        "email": ["Email", false, "text"]
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await callApi.getCall("Teacher")
            setTeachers(response)
            setFiltredData(response)
        }

        document.getElementById('searchInput').focus();
        fetchData();
    }, []);

    const handleFilter = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        const filtered = teachers.filter(teachers =>
            teachers.name.toLowerCase().includes(searchTerm) ||
            teachers.surname.toLowerCase().includes(searchTerm)
        );

        setFiltredData(filtered);
    };

    const deleteTeacher = (id, surname) => {
        callApi.deleteCall("teacher/Delete/" + id, 'al profesor ' + surname);
    }

    const addTeacher = () => {

        withReactContent(Swal).fire({
            title: 'Agregar Profesor',
            html: <ModalFormTeacher inputs={inputs} idForm="addForm" />,
            width: '60%',
            showConfirmButton: false,
        }).then((result) => {
            if (result.isConfirmed) {
                callApi.callApiNoReadWithModal("teacher", "#addForm");
            }
        });
    }

    const  updateTeacher = async (id) => {      

        //Get data teacher
        let data = await callApi.getCall("teacher/" + id);
        await withReactContent(Swal).fire({
            title: 'Modificar Profesor',
            html: <ModalFormTeacher updateTeacher={updateTeacher} data={data} inputs={inputs} idForm="updateForm" />,
            width: '90%',
            showConfirmButton: false,
            showCloseButton: true

        }).then((result) => {
            if (result.isConfirmed) {
                callApi.callApiNoReadWithModal("teacher/Update/", "#updateForm", id);
            }
        });
    }

    return (
        <div>
            <main className="container">
                <button onClick={addTeacher} className="btn btn-success mt-4">Agregar nuevo profesor</button>
                <input type="text" id="searchInput" placeholder="Buscar Profesor" className="form-control mt-4" onChange={handleFilter} />
                <table className="table mt-3 table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Apellido</th>
                            <th scope="col">Telefono</th>
                            <th scope="col">email</th>
                            <th scope="col">Modificar</th>
                            <th scope="col">Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtredData ? (
                            filtredData.map((item) => (
                                <tr key={item.id}>
                                    <td className="col-md-3">{item.name}</td>
                                    <td className="col-md-3">{item.surname}</td>
                                    <td className="col-md-3">{item.phone}</td>
                                    <td className="col-md-3">{item.email}</td>
                                    <td><button onClick={() => updateTeacher(item.id)} className="btn btn-warning">Modificar</button></td>
                                    <td><button onClick={() => deleteTeacher(item.id, item.surname)} className="btn btn-danger">Eliminar</button></td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td>Cargando...</td>
                                <td>Cargando...</td>
                                <td>Cargando...</td>
                                <td>Cargando...</td>
                                <td>Cargando...</td>
                                <td>Cargando...</td>
                                <td>Cargando...</td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </main>

        </div>
    )
}
export default Teachers