import React from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

const ModalFormTeacher = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = () => {
    Swal.clickConfirm()
  };

  const closeModal = () => {
    Swal.clickCancel()
  };


  //Conditional input, if the form is an update form, the defaultValue will be filled in the try. 
  //Else, the input will not have a defaultValue

  function Input({ allProps, theKey }) {
    let input;
    try {
      input = <input
        id={theKey}
        type={allProps.inputs[theKey][2]}
        defaultValue={allProps.data[theKey]}
        className={errors[theKey] ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
        {...register(theKey, { required: allProps.inputs[theKey][1] })} />
    } catch {
      input = <input
        id={theKey}
        type={allProps.inputs[theKey][2]}
        className={errors[theKey] ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
        {...register(theKey, { required: allProps.inputs[theKey][1] })} />
    }
    return input
  }

  //create the form with the inputs of the prop "inputs"
  return (
    <form id={props.idForm} className="container-fluid mt-5" onSubmit={handleSubmit(onSubmit)}>
      <div className='row justify-content-center mb-3'>
        {Object.keys(props.inputs).map((key, index) =>
          <div key={index} className='col-3'>
            <label className='form-label'>{props.inputs[key][0]}</label>
            <Input allProps={props} theKey={key} />
          </div>
        )}

        <textarea placeholder='Observaciones' defaultValue={props.data ? props.data['remarks'] : ''} className="form-control" {...register('remarks')} rows="3"></textarea>
        <button className='swal2-confirm swal2-styled mt-5 w-25' type="submit" >Guardar</button>

        {/*update Form*/}


        {props.idForm === "updateForm" && (
          <div>
            <hr />

            <div className='pb-5 mb-4'>
              <h4 className='mt-5 pt-5 '>Grupos a los que pertenece</h4>
              <ul className='mt-5 list-group list-group-flush'>
                {props.data.Groups && props.data.Groups.map((group, index) => (
                  <li className='list-group-item' key={index}>
                    {group.name}
                  </li>
                ))}
              </ul>
            </div>

          </div>

        )}
        <hr />


      </div>
      <button onClick={closeModal} type="button" className="swal2-cancel swal2-styled" aria-label="" >Cancelar</button>
    </form>
  );
};

export default ModalFormTeacher;
