// GroupAttendance.jsx

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import useCallApi from "../hooks/useCallApi";

function GroupAttendance() {
  const params = useParams();
  const callApi = useCallApi();
  const mySwal = withReactContent(Swal);

  const [classData, setClassData] = useState({
    Suspend: false,       // bool
    CommentSuspend: "",   // string
    IdCourseGroup: 0      // int
  });

  // fetchedData is what we get from the server, including Students, LastClassNumber, etc.
  const [fetchedData, setFetchedData] = useState(null);

  // We'll show these on screen but not send them
  const [displayDate, setDisplayDate] = useState("");
  const [displayClassNumber, setDisplayClassNumber] = useState(null);

  // attendanceState for each student: { status: 1 (present) or 0 (absent), comment: "" }
  const [attendanceState, setAttendanceState] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callApi.getCall(
          "Class/ByIdCourseGroup/" + params.group
        );
        console.log("Server response:", response);

        // We store the raw server data
        setFetchedData(response);

        // We'll display date & class number to the user, but not send them
        setDisplayDate(new Date().toLocaleDateString("es-AR"));
        if (response?.LastClassNumber !== undefined) {
          setDisplayClassNumber(response.LastClassNumber + 1);
        }

        // We fill courseGroupId so it's not 0
        setClassData((prev) => ({
          ...prev,
          courseGroupId: response.IdCourseGroup ?? 0
        }));

        // Initialize attendanceState: default = present (1)
        const defaultAttendance = {};
        response.Students.forEach((student) => {
          defaultAttendance[student.StudentId] = {
            status: 1,
            comment: ""
          };
        });
        setAttendanceState(defaultAttendance);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [params.group]);

  // Update present/absent status
  const handleAttendanceChange = (studentId, newValue) => {
    setAttendanceState((prev) => ({
      ...prev,
      [studentId]: {
        ...prev[studentId],
        status: newValue
      }
    }));
  };

  // Update comment for an absent student
  const handleCommentChange = (studentId, newComment) => {
    setAttendanceState((prev) => ({
      ...prev,
      [studentId]: {
        ...prev[studentId],
        comment: newComment
      }
    }));
  };

  // Opens a SweetAlert to ask for suspension reason
  const handleSuspendClass = () => {
    mySwal
      .fire({
        title: "Suspender Clase",
        html: (
          <div>
            <label>
              Motivo de suspensión <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              id="suspendReason"
              className="form-control mt-2"
              placeholder="Escribe el motivo aquí..."
            />
          </div>
        ),
        showCancelButton: true,
        cancelButtonText: "Cerrar",
        confirmButtonText: "Cancelar Clase",
        preConfirm: () => {
          const reason = document
            .getElementById("suspendReason")
            ?.value.trim();
          if (!reason) {
            Swal.showValidationMessage(
              "Debes ingresar un motivo de suspensión"
            );
            return false;
          }
          return reason;
        }
      })
      .then((result) => {
        if (result.isConfirmed && result.value) {
          // Mark the class as suspended with the given comment
          setClassData((prev) => ({
            ...prev,
            isSuspended: true,
            suspendComment: result.value
          }));

          mySwal.fire({
            icon: "info",
            title: "Clase suspendida",
            text: `Motivo: ${result.value}`
          });
        }
      });
  };

  const handleSave = async () => {
    try {
      // Build the attendance list (no 'id' or 'idClass')
      const attendanceList = Object.entries(attendanceState).map(
        ([studentId, value]) => ({
          idStudent: parseInt(studentId),
          present: value.status === 1, // true if status=1, false if status=0
          absentComment: value.comment || ""
        })
      );
  
      // Build the final object to send, using the exact names from your C# model
      const classWithAttendance = {
        classInfo: {
          Suspend: classData.Suspend,
          CommentSuspend: classData.CommentSuspend,
          IdCourseGroup: parseInt(params.group) // We force the ID from the URL
        },
        attendanceList
      };
  
      console.log("JSON to send:", classWithAttendance);
  
      // Call your custom hook: it returns true (success) or false (error)
      const success = await callApi.callApiNoRead("Class", classWithAttendance, "POST");
      
      // If success is true, the operation was completed successfully
      if (success) {
        // Wait 1.5 seconds before reloading the page
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        // If there was an error, show an additional Swal or do other logic
        Swal.fire({
          icon: "error",
          title: "Hubo un problema al crear la clase",
          text: "El servidor devolvió un error o no se pudo completar la operación.",
          confirmButtonText: "Aceptar"
        });
      }
    } catch (error) {
      // If a network/connection error occurs, we catch it here
      console.error("Error creando la clase y asistencia:", error);
      Swal.fire({
        icon: "error",
        title: "Error de conexión",
        text: error.message,
        confirmButtonText: "Aceptar"
      });
    }
  };
  
  
  
  

  return (
    <>
      {fetchedData === null ? (
        <div>Cargando...</div>
      ) : fetchedData !== 0 ? (
        <>
          {/* Header: the user sees the date and class number, but we don't send them */}
          <div className="row mt-5">
            <div className="col-md-3">
              <h1 className="fs-5">Asistencia {fetchedData.CourseGroupName}</h1>
            </div>
            <div className="col-md-3">
              <h3 className="fs-6">Fecha: {displayDate}</h3>
            </div>
            <div className="col-md-3">
              <h3 className="fs-6">Clase N°: {displayClassNumber}</h3>
            </div>
            <div className="col-md-3 text-end">
              {!classData.isSuspended ? (
                <button
                  className="btn btn-outline-danger"
                  onClick={handleSuspendClass}
                >
                  Suspender Clase
                </button>
              ) : (
                <p className="text-danger">
                  Clase suspendida. Motivo: {classData.suspendComment}
                </p>
              )}
            </div>
          </div>

          <hr />

          {/* Table of students */}
          <div className="container mt-4">
            <table className="table my-4 table-hover">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Apellido</th>
                  <th scope="col">Asistencia</th>
                  <th scope="col">Comentario Ausente</th>
                </tr>
              </thead>
              <tbody>
                {fetchedData.Students.map((student, indexStudent) => {
                  const isAbsent =
                    attendanceState[student.StudentId]?.status === 0;
                  return (
                    <tr key={indexStudent}>
                      <td className="col-md-3">{student.StudentName}</td>
                      <td className="col-md-3">{student.StudentSurname}</td>
                      <td className="col-md-2">
                        <select
                          className={`form-select ${
                            isAbsent ? "text-danger" : "text-success"
                          }`}
                          value={isAbsent ? "0" : "1"}
                          onChange={(e) =>
                            handleAttendanceChange(
                              student.StudentId,
                              Number(e.target.value)
                            )
                          }
                        >
                          <option value="1">Presente</option>
                          <option value="0">Ausente</option>
                        </select>
                      </td>
                      <td className="col-md-4">
                        {isAbsent ? (
                          <input
                            type="text"
                            className="form-control"
                            value={
                              attendanceState[student.StudentId]?.comment || ""
                            }
                            onChange={(e) =>
                              handleCommentChange(
                                student.StudentId,
                                e.target.value
                              )
                            }
                          />
                        ) : (
                          <span style={{ color: "#999" }}>-</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* Button to console.log the final JSON */}
            <div className="col-md-2">
              <button onClick={handleSave} className="btn btn-primary mt-2">
                Guardar Cambios
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="mt-5">
          No hay alumnos en el grupo o módulos en el curso
        </div>
      )}
    </>
  );
}

export default GroupAttendance;
